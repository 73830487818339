import React from 'react'

export const EmailSvgIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.675 10.1L9.35714 6.2M5.64286 6.2L1.32502 10.1M1 2.95L6.3072 6.66504C6.73696 6.96587 6.95184 7.11629 7.18557 7.17455C7.39203 7.22601 7.60797 7.22601 7.81443 7.17455C8.04816 7.11629 8.26304 6.96587 8.6928 6.66504L14 2.95M4.12 11.4H10.88C11.9721 11.4 12.5182 11.4 12.9353 11.1875C13.3022 11.0005 13.6005 10.7022 13.7875 10.3353C14 9.91815 14 9.3721 14 8.28V4.12C14 3.0279 14 2.48185 13.7875 2.06472C13.6005 1.6978 13.3022 1.39949 12.9353 1.21254C12.5182 1 11.9721 1 10.88 1H4.12C3.0279 1 2.48185 1 2.06472 1.21254C1.6978 1.39949 1.39949 1.6978 1.21254 2.06472C1 2.48185 1 3.0279 1 4.12V8.28C1 9.3721 1 9.91815 1.21254 10.3353C1.39949 10.7022 1.6978 11.0005 2.06472 11.1875C2.48185 11.4 3.0279 11.4 4.12 11.4Z" stroke="#3B7358" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.12" d="M8.6928 6.66504C8.26304 6.96587 8.04816 7.11629 7.81443 7.17455C7.60797 7.22602 7.39203 7.22602 7.18557 7.17455C6.95184 7.11629 6.73696 6.96587 6.3072 6.66504L1 2.95C1 1.87304 1.87304 1 2.95 1H12.05C13.127 1 14 1.87304 14 2.95L8.6928 6.66504Z" fill="#3B7358" />
        </svg>
    )
}
export const TickSvgIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 8.27247 0 6.4087 0.608964 4.93853C1.42092 2.97831 2.97831 1.42092 4.93853 0.608964C6.4087 0 8.27247 0 12 0C15.7275 0 17.5913 0 19.0615 0.608964C21.0217 1.42092 22.5791 2.97831 23.391 4.93853C24 6.4087 24 8.27247 24 12C24 15.7275 24 17.5913 23.391 19.0615C22.5791 21.0217 21.0217 22.5791 19.0615 23.391C17.5913 24 15.7275 24 12 24C8.27247 24 6.4087 24 4.93853 23.391C2.97831 22.5791 1.42092 21.0217 0.608964 19.0615C0 17.5913 0 15.7275 0 12Z" fill="#E4F3EC" />
            <circle cx="11.9998" cy="11.9998" r="5.31818" fill="#D0E4DA" />
            <path d="M10.9623 14.5492L8.45508 12.0414L9.29062 11.2059L10.9623 12.877L14.3045 9.53418L15.1406 10.3703L10.9623 14.5492Z" fill="#3B7358" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 12C5.5 8.41023 8.41023 5.5 12 5.5C15.5898 5.5 18.5 8.41023 18.5 12C18.5 15.5898 15.5898 18.5 12 18.5C8.41023 18.5 5.5 15.5898 5.5 12ZM12 17.3182C11.3016 17.3182 10.6101 17.1806 9.96482 16.9134C9.31959 16.6461 8.73332 16.2544 8.23948 15.7605C7.74564 15.2667 7.3539 14.6804 7.08664 14.0352C6.81938 13.3899 6.68182 12.6984 6.68182 12C6.68182 11.3016 6.81938 10.6101 7.08664 9.96482C7.3539 9.31959 7.74564 8.73332 8.23948 8.23948C8.73332 7.74564 9.31959 7.3539 9.96482 7.08664C10.6101 6.81938 11.3016 6.68182 12 6.68182C13.4105 6.68182 14.7632 7.24212 15.7605 8.23948C16.7579 9.23683 17.3182 10.5895 17.3182 12C17.3182 13.4105 16.7579 14.7632 15.7605 15.7605C14.7632 16.7579 13.4105 17.3182 12 17.3182Z" fill="#3B7358" />
        </svg>
    )
}
export const EyesSvgIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 8.27247 0 6.4087 0.608964 4.93853C1.42092 2.97831 2.97831 1.42092 4.93853 0.608964C6.4087 0 8.27247 0 12 0C15.7275 0 17.5913 0 19.0615 0.608964C21.0217 1.42092 22.5791 2.97831 23.391 4.93853C24 6.4087 24 8.27247 24 12C24 15.7275 24 17.5913 23.391 19.0615C22.5791 21.0217 21.0217 22.5791 19.0615 23.391C17.5913 24 15.7275 24 12 24C8.27247 24 6.4087 24 4.93853 23.391C2.97831 22.5791 1.42092 21.0217 0.608964 19.0615C0 17.5913 0 15.7275 0 12Z" fill="#E4F3EC" />
            <path opacity="0.2" d="M11.9983 8.1001C7.66498 8.1001 5.93164 12.0001 5.93164 12.0001C5.93164 12.0001 7.66498 15.9001 11.9983 15.9001C16.3316 15.9001 18.065 12.0001 18.065 12.0001C18.065 12.0001 16.3316 8.1001 11.9983 8.1001ZM11.9983 14.1668C11.5698 14.1668 11.1509 14.0397 10.7946 13.8016C10.4383 13.5635 10.1606 13.2252 9.99657 12.8292C9.83258 12.4333 9.78967 11.9977 9.87328 11.5774C9.95688 11.1571 10.1632 10.771 10.4662 10.468C10.7693 10.165 11.1553 9.95867 11.5756 9.87506C11.9959 9.79146 12.4316 9.83437 12.8275 9.99836C13.2234 10.1624 13.5618 10.4401 13.7998 10.7964C14.0379 11.1527 14.165 11.5716 14.165 12.0001C14.165 12.5747 13.9367 13.1258 13.5304 13.5322C13.124 13.9385 12.5729 14.1668 11.9983 14.1668Z" fill="#3B7358" />
            <circle cx="12" cy="12" r="2" fill="#C2D9CE" />
            <path d="M18.4626 11.8243C18.4437 11.7815 17.9849 10.7638 16.9649 9.7438C15.6059 8.38475 13.8893 7.6665 12 7.6665C10.1107 7.6665 8.39412 8.38475 7.03508 9.7438C6.01512 10.7638 5.55416 11.7832 5.53737 11.8243C5.51273 11.8798 5.5 11.9397 5.5 12.0004C5.5 12.061 5.51273 12.121 5.53737 12.1764C5.55633 12.2192 6.01512 13.2365 7.03508 14.2564C8.39412 15.6149 10.1107 16.3332 12 16.3332C13.8893 16.3332 15.6059 15.6149 16.9649 14.2564C17.9849 13.2365 18.4437 12.2192 18.4626 12.1764C18.4873 12.121 18.5 12.061 18.5 12.0004C18.5 11.9397 18.4873 11.8798 18.4626 11.8243ZM12 15.4665C10.3327 15.4665 8.87621 14.8604 7.67045 13.6655C7.17572 13.1735 6.75481 12.6124 6.42083 11.9998C6.75472 11.3872 7.17564 10.8261 7.67045 10.3342C8.87621 9.1393 10.3327 8.53317 12 8.53317C13.6673 8.53317 15.1238 9.1393 16.3295 10.3342C16.8252 10.826 17.2471 11.3871 17.5819 11.9998C17.1913 12.7289 15.49 15.4665 12 15.4665ZM12 9.39984C11.4858 9.39984 10.9831 9.55233 10.5555 9.83802C10.1279 10.1237 9.7947 10.5298 9.59791 11.0049C9.40112 11.48 9.34963 12.0027 9.44996 12.5071C9.55028 13.0114 9.7979 13.4747 10.1615 13.8383C10.5251 14.2019 10.9884 14.4496 11.4928 14.5499C11.9971 14.6502 12.5199 14.5987 12.995 14.4019C13.4701 14.2051 13.8761 13.8719 14.1618 13.4443C14.4475 13.0168 14.6 12.5141 14.6 11.9998C14.5993 11.3105 14.3251 10.6496 13.8377 10.1622C13.3502 9.67471 12.6893 9.40056 12 9.39984ZM12 13.7332C11.6572 13.7332 11.3221 13.6315 11.037 13.4411C10.752 13.2506 10.5298 12.9799 10.3986 12.6632C10.2674 12.3464 10.2331 11.9979 10.3 11.6617C10.3669 11.3254 10.5319 11.0166 10.7743 10.7742C11.0168 10.5318 11.3256 10.3667 11.6618 10.2998C11.9981 10.2329 12.3466 10.2673 12.6633 10.3984C12.98 10.5296 13.2508 10.7518 13.4412 11.0369C13.6317 11.3219 13.7333 11.657 13.7333 11.9998C13.7333 12.4595 13.5507 12.9004 13.2257 13.2255C12.9006 13.5506 12.4597 13.7332 12 13.7332Z" fill="#3B7358" />
        </svg>

    )
}
export const LocationSvgIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 8.27247 0 6.4087 0.608964 4.93853C1.42092 2.97831 2.97831 1.42092 4.93853 0.608964C6.4087 0 8.27247 0 12 0C15.7275 0 17.5913 0 19.0615 0.608964C21.0217 1.42092 22.5791 2.97831 23.391 4.93853C24 6.4087 24 8.27247 24 12C24 15.7275 24 17.5913 23.391 19.0615C22.5791 21.0217 21.0217 22.5791 19.0615 23.391C17.5913 24 15.7275 24 12 24C8.27247 24 6.4087 24 4.93853 23.391C2.97831 22.5791 1.42092 21.0217 0.608964 19.0615C0 17.5913 0 15.7275 0 12Z" fill="#E4F3EC" />
            <path opacity="0.12" d="M11.9996 15.25C12.9746 13.3 15.8996 12.0414 15.8996 9.4C15.8996 7.24609 14.1535 5.5 11.9996 5.5C9.8457 5.5 8.09961 7.24609 8.09961 9.4C8.09961 12.0414 11.0246 13.3 11.9996 15.25Z" fill="#3B7358" />
            <path d="M7.45 13.4861C6.24661 14.017 5.5 14.7568 5.5 15.575C5.5 17.1904 8.41015 18.5 12 18.5C15.5899 18.5 18.5 17.1904 18.5 15.575C18.5 14.7568 17.7534 14.017 16.55 13.4861M15.9 9.4C15.9 12.0414 12.975 13.3 12 15.25C11.025 13.3 8.1 12.0414 8.1 9.4C8.1 7.24609 9.84609 5.5 12 5.5C14.1539 5.5 15.9 7.24609 15.9 9.4ZM12.65 9.4C12.65 9.75898 12.359 10.05 12 10.05C11.641 10.05 11.35 9.75898 11.35 9.4C11.35 9.04101 11.641 8.75 12 8.75C12.359 8.75 12.65 9.04101 12.65 9.4Z" stroke="#3B7358" strokeLinecap="round" strokeLinejoin="round" />
        </svg>


    )
}
export const CrossSvgIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 12C0 8.27247 0 6.4087 0.608964 4.93853C1.42092 2.97831 2.97831 1.42092 4.93853 0.608964C6.4087 0 8.27247 0 12 0C15.7275 0 17.5913 0 19.0615 0.608964C21.0217 1.42092 22.5791 2.97831 23.391 4.93853C24 6.4087 24 8.27247 24 12C24 15.7275 24 17.5913 23.391 19.0615C22.5791 21.0217 21.0217 22.5791 19.0615 23.391C17.5913 24 15.7275 24 12 24C8.27247 24 6.4087 24 4.93853 23.391C2.97831 22.5791 1.42092 21.0217 0.608964 19.0615C0 17.5913 0 15.7275 0 12Z" fill="#E4F3EC" />
            <circle cx="12.0004" cy="11.9999" r="5.85" fill="#D0E4DA" />
            <path d="M9.66 15.25L12 12.91L14.34 15.25L15.25 14.34L12.91 12L15.25 9.66L14.34 8.75L12 11.09L9.66 8.75L8.75 9.66L11.09 12L8.75 14.34L9.66 15.25ZM12 18.5C11.1008 18.5 10.2558 18.3293 9.465 17.9878C8.67417 17.6463 7.98625 17.1833 7.40125 16.5987C6.81625 16.0137 6.35323 15.3258 6.0122 14.535C5.67117 13.7442 5.50043 12.8992 5.5 12C5.5 11.1008 5.67073 10.2558 6.0122 9.465C6.35367 8.67417 6.81668 7.98625 7.40125 7.40125C7.98625 6.81625 8.67417 6.35323 9.465 6.0122C10.2558 5.67117 11.1008 5.50043 12 5.5C12.8992 5.5 13.7442 5.67073 14.535 6.0122C15.3258 6.35367 16.0137 6.81668 16.5987 7.40125C17.1837 7.98625 17.647 8.67417 17.9884 9.465C18.3299 10.2558 18.5004 11.1008 18.5 12C18.5 12.8992 18.3293 13.7442 17.9878 14.535C17.6463 15.3258 17.1833 16.0137 16.5987 16.5987C16.0137 17.1837 15.3258 17.647 14.535 17.9884C13.7442 18.3299 12.8992 18.5004 12 18.5ZM12 17.2C13.4517 17.2 14.6812 16.6962 15.6887 15.6887C16.6962 14.6812 17.2 13.4517 17.2 12C17.2 10.5483 16.6962 9.31875 15.6887 8.31125C14.6812 7.30375 13.4517 6.8 12 6.8C10.5483 6.8 9.31875 7.30375 8.31125 8.31125C7.30375 9.31875 6.8 10.5483 6.8 12C6.8 13.4517 7.30375 14.6812 8.31125 15.6887C9.31875 16.6962 10.5483 17.2 12 17.2Z" fill="#3B7358" />
        </svg>
    )
}

export const Camera = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.264 2.54145C9.288 2.58341 9.33 2.61337 9.384 2.61337C10.824 2.61337 12 3.78807 12 5.22647V8.78651C12 10.2249 10.824 11.3996 9.384 11.3996H2.616C1.17 11.3996 0 10.2249 0 8.78651V5.22647C0 3.78807 1.17 2.61337 2.616 2.61337C2.664 2.61337 2.712 2.5894 2.73 2.54145L2.766 2.46953C2.78668 2.42599 2.80792 2.38128 2.82947 2.33588C2.98296 2.01257 3.15277 1.65489 3.258 1.44467C3.534 0.90527 4.002 0.605603 4.584 0.599609H7.41C7.992 0.605603 8.466 0.90527 8.742 1.44467C8.83651 1.63348 8.9805 1.93761 9.11928 2.23074C9.1479 2.2912 9.1763 2.35119 9.204 2.4096L9.264 2.54145ZM8.826 4.84289C8.826 5.14256 9.066 5.3823 9.366 5.3823C9.666 5.3823 9.912 5.14256 9.912 4.84289C9.912 4.54323 9.666 4.2975 9.366 4.2975C9.066 4.2975 8.826 4.54323 8.826 4.84289ZM4.962 5.77186C5.244 5.49018 5.61 5.34034 6 5.34034C6.39 5.34034 6.756 5.49018 7.032 5.76587C7.308 6.04156 7.458 6.40716 7.458 6.79672C7.452 7.59983 6.804 8.25311 6 8.25311C5.61 8.25311 5.244 8.10327 4.968 7.82758C4.692 7.55188 4.542 7.18629 4.542 6.79672V6.79073C4.536 6.41315 4.686 6.04756 4.962 5.77186ZM7.662 8.46287C7.236 8.8884 6.648 9.15211 6 9.15211C5.37 9.15211 4.782 8.90638 4.332 8.46287C3.888 8.01337 3.642 7.42602 3.642 6.79672C3.636 6.17342 3.882 5.58607 4.326 5.13657C4.776 4.68707 5.37 4.44134 6 4.44134C6.63 4.44134 7.224 4.68707 7.668 5.13057C8.112 5.58008 8.358 6.17342 8.358 6.79672C8.352 7.45 8.088 8.03735 7.662 8.46287Z" fill="white" />
        </svg>

    )
}