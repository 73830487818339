import React from 'react'
import { addressIcon, branchProfileImage, callIcon, categoryIcon, emailIcon, locationIcon, pincodeIcon } from '../../assets'
import styles from "./ShopOwnerModal.module.scss";
import { useNavigate } from 'react-router-dom';

const ShopOwnerModal = ({branchesData}) => {

  const navigate = useNavigate()
  const viewDashboardButtonHandler = ()=>{
    navigate(`/admin/shops/shopDashboard/${branchesData?._id}`)
  }

const clickHandler = (e)=>{
    e.stopPropagation()
}

  return (
    <div className={styles.outerCardContainer} onClick={(e)=>clickHandler(e)}>
    <div className={styles.cardContainer}>
      <div className={styles.leftSide}>
        <div className={styles.profile}>
          <img src={branchProfileImage} alt="" />
          <div className={styles.details}>
            <h2>Branch One</h2>
            <div className={styles.location}>
              <img src={locationIcon} alt="" />
              <h6>Jaipur, Rajasthan</h6>
            </div>
          </div>
        </div>
        <div className={styles.lowerBox}>
          <div className={styles.child}>
            <span>Artists</span>
            <h6>643</h6>
          </div>
          <div className={styles.child}>
            <span>Customers</span>
            <h6>76</h6>
          </div>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.rightSide}>
        <span>Branch Detail</span>
        <div className={styles.detailsBox}>
          <img src={emailIcon} alt="" />
          <div className={styles.rightDescription}>
            <span>Email</span>
            <h6>abc@gmail.com</h6>
          </div>
        </div>
        <div className={styles.detailsBox}>
          <img src={callIcon} alt="" />
          <div className={styles.rightDescription}>
            <span>Phone number</span>
            <h6>+91 9876543210</h6>
          </div>
        </div>
        <div className={styles.detailsBox}>
          <img src={categoryIcon} alt="" />
          <div className={styles.rightDescription}>
            <span>Category</span>
            <h6>Male</h6>
          </div>
        </div>
        <div className={styles.detailsBox}>
          <img src={pincodeIcon} alt="" />
          <div className={styles.rightDescription}>
            <span>Pincode</span>
            <h6>302012</h6>
          </div>
        </div>
        <div className={styles.detailsBox}>
          <img src={addressIcon} alt="" />
          <div className={styles.rightDescription}>
            <span>Address</span>
            <h6>11-a Jaipur Rajasthan</h6>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.buttonBox}>
        <button className={styles.location}> <img src={addressIcon} alt="" /> Location</button>
        <button className={styles.dashboard} onClick={viewDashboardButtonHandler}>View Dashboard</button>
    </div>
    </div>
  )
}

export default ShopOwnerModal
