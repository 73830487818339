import React, { useState } from 'react'
import { shopColumnName, shopList } from '../constants/ShopConstants';
import style from '../subpages/shopBranches/shopBranch.module.scss'
import ShopOwnerModal from '../modals/ShopOwnerModal/ShopOwnerModal';
import Modal from '../modals/Modal';

const ShopBranchTable = () => {

  const [modal, setModal] = useState(false)
  const [branchesData, setBranchesData] = useState(null)

  const toggleModal = (list) => {
    setBranchesData(list)
    console.log("Modal clicked")
    setModal(!modal)
  }

  return (
    <div className={style.container}>
      <div className="DBdataGridBox">
        <table className="DBdataGrid">
          <thead>
            <tr>
              {shopColumnName.map((col, index) => {
                return <th id={col.id} key={index}>{col.name}</th>;
              })}
            </tr>
          </thead>

          <tbody>
            {shopList.map((list) => {
              return (
                <tr key={list.id} onClick={() => toggleModal(list)}>
                  <td>{list.id} </td>
                  <td>{list.owner}</td>
                  <td style={{ textAlign: "right" }}>{list.branches}</td>
                  <td>{list.email}</td>
                  <td>{list.phone}</td>
                  <td>{list.address}</td>
                  {(list.serviceType === "Male") && (
                    <td className="male">{list.serviceType}</td>
                  )}
                  {(list.serviceType === "Female") && (
                    <td className="female">{list.serviceType}</td>
                  )}
                  {(list.serviceType === "Unisex") && (
                    <td className="unisex">{list.serviceType}</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          {modal &&
            <Modal
              open={modal}
              closeModal={setModal}
            >
              <ShopOwnerModal branchesData={branchesData}/>
            </Modal>
          }
        </div>
      </div>
    </div>

  )
}

export default ShopBranchTable
