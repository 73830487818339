import React from 'react'
import styles from './ShopDashboardPage.module.scss'

const ArtistsContainer = () => {
  return (
    <div className={styles.artistContainer}>
      <div className={styles.heading}>
        <h3>Artists</h3>
        <button>View all</button>
      </div>
    </div>
  )
}

export default ArtistsContainer
