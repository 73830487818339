import instance from "../axiosInstance";

export const getProfileByIdService = async (id) => {
    try {
        const response = await instance.get(`/admin/profile/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching customers:', error);
        return error;
    }
}

export const editProfileByIdService = async (id, data) => {
    try {
        console.log("......",id,data)
        const response = await instance.put(`/admin/profile/${id}`, data)
        return response.data;
    } catch (error) {
        console.error('Error editing profile: ', error)
        return error;
    }
}   