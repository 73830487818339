import React from "react";
import "./Table.scss";
import PageHeader from "../layout/pageHeader/PageHeader";
import { useNavigate } from "react-router-dom";

function ShopTable({ shopColumnName, approvedProfilesData }) {
  const navigate = useNavigate()

  const shopDashboardNavigateHandler = (id) => {
    // console.log("firs",id)
    navigate(`/admin/shops/shopDashboard/${id}`)
  }

  return (
    <div className="DBdataGridBox">
      <PageHeader />
      <table className="DBdataGrid">
        <thead>
          <tr>
            {shopColumnName.map((col, index) => {
              return <th id={col.id} key={index}>{col.name}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          {approvedProfilesData?.map((approvedProfileData, index) => {
            return (
              <tr key={index}
                onClick={() => shopDashboardNavigateHandler(approvedProfileData?._id)}>
                <td>{index+1} </td>
                <td>{approvedProfileData?.ownerName}</td>
                <td style={{textAlign:"right"}}>{approvedProfileData?.branches}</td>
                <td>{approvedProfileData?.email}</td>
                <td>{approvedProfileData?.phone}</td>
                {/* <td>{approvedProfileData?.address}</td> */}
                {(approvedProfileData?.serviceType === "Male") && (
                  <td className="male">{approvedProfileData?.serviceType}</td>
                )}
                {(approvedProfileData?.serviceType === "Female") && (
                  <td className="female">{approvedProfileData?.serviceType}</td>
                )}
                {(approvedProfileData?.serviceType === "Unisex") && (
                  <td className="unisex">{approvedProfileData?.serviceType}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ShopTable;
