import React, { useState } from "react";
import AdminHeader from "../../layout/adminheader/AdminHeader";
import BranchContainer from "./BranchContainer";
import ShopBranchTable from '../../tables/ShopBranchTable'
import PageHeader from "../../layout/pageHeader/PageHeader";

const ShopBranches = () => {

  const [menuOptionIcon, setMenuOptionIcon] = useState(true)

  const toggleIconClickHandler=()=>{
    setMenuOptionIcon(!menuOptionIcon)
  }

  return (
    <div className="middleData">
      <AdminHeader title="ABC Barber Shop" />
      <div className="DBdataGridBox">
        <PageHeader heading="Branch List" 
        menuOptionIcon={menuOptionIcon}
        toggleIconClickHandler={toggleIconClickHandler}
         />
      </div>
      {menuOptionIcon ? <BranchContainer /> : <ShopBranchTable />}
    </div>
  );
};

export default ShopBranches;
