import { constRightSidebarToggle } from "../constants/Constants"

export const RightSideBarToggleHooks = (name,data) => {
    let tempConstRightSidebarToggle = structuredClone(constRightSidebarToggle)
    tempConstRightSidebarToggle[name] = !data[name] 
    return tempConstRightSidebarToggle
}

export const setTokenInLocalStorage = (name,value) => {
    localStorage.setItem(name, value);
  };
  
  // Function to get token from local storage
  export const getTokenFromLocalStorage = (name) => {
    return localStorage.getItem(name);
  };