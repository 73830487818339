import React from 'react'
import styles from "./ShopDashboardPage.module.scss";
import {starImage} from '../../assets'

const ShopRating = () => {
  return (
    <div className={styles.container}>
      <div className={styles.numberRating}>
        <h1>4.5 <img src={starImage} alt="" /></h1>
        <button>See all 63 reviews</button>
      </div>
    </div>
  )
}

export default ShopRating
