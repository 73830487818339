import React from "react";
import AdminHeader from "../../layout/adminheader/AdminHeader";
import OwnerProfile from "./OwnerProfile";
import ShopRating from "./ShopRating";
import styles from "./ShopDashboardPage.module.scss";
import ArtistsContainer from "./ArtistsContainer";
import CustomerList from "./CustomerList";

const ShopDashboard = () => {
  return (
    <div className="middleData">
      <AdminHeader title="Shop Owner" />
      <div className={styles.outerContainer}>
        <div className={styles.profileDetails}>
          <OwnerProfile />
          <ShopRating />
        </div>
        <div className={styles.tableContainer}>
          <ArtistsContainer />
          <CustomerList />
        </div>
      </div>
    </div>
  );
};

export default ShopDashboard;
