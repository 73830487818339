import React, { useCallback, useEffect, useState } from 'react';
import AdminHeader from '../../../layout/adminheader/AdminHeader';
import { shopColumnName } from '../../../constants/ShopConstants';
import ShopTable from '../../../tables/ShopTable';
import { getShopOwnerApprovedProfiles } from '../../../apiServices/Shop/ShopApiServices';

const Shops = () => {

  const [approvedProfilesData, setApprovedProfilesData] = useState([])

  const getApprovedProfilesData = useCallback(()=>{
    getShopOwnerApprovedProfiles()
    .then((res)=>{
      if(res?.data.length > 0)
        setApprovedProfilesData(res?.data)
      // console.log("Approved Profile Data",res?.data)
  })
  },[])

  useEffect(()=>{
    getApprovedProfilesData()
  },[getApprovedProfilesData])

  console.log("Approved Profiles are",approvedProfilesData)

  return (
    <div className="middleData">
      <AdminHeader title="Shop Owners" />
      <ShopTable shopColumnName={shopColumnName} approvedProfilesData={approvedProfilesData} />
    </div>
  );
};

export default Shops;
